<!-- 상품옵션 견적요청 내역 -->
<template>
  <div class="file-uploader" :class="{ 'image-mode' : showImage }">

    <!-- 첨부파일 추가하기 -->
    <input class="file-input-upload"
          :id="inputId"
          :accept="accept"
          @change="onFileChange"
          type="file">

    <label :for="inputId">
      <div class="file-attachment-wrap"
        v-if="!uploadedFile.file && !uploadedFile.name && !showImage">
        <div class="file-attachment-text">{{ placeholder }}</div>
      </div>
      <!-- 미리보기 이미지 -->
      <div v-if="showImage" style="position: relative;">
        <div v-if="uploadedFile.file" class="uploaded-file-image" @click.stop.prevent="$refs.previewModal.open()"
          :style="`backgroundImage: url(${uploadedFile.file})`">
          <div class="remove-wrap-image">
            <i class="material-icons remove-icon"
              @click.stop.prevent="uploadedFile = { file:'', name: '' }">close</i>
          </div>
        </div>
        <div v-if="!uploadedFile.file"
          class="uploaded-file-image"
          style="background: #c7c7c7; display:flex; align-items:center; justify-content:center;">
          <i class="material-icons"
            style="font-size: 44px; color: #fff;">add_photo_alternate</i>
        </div>
      </div>
    </label>

    <!-- 이미지 이름 -->
    <div v-if="!showImage" style="position: relative;"
      :class="{ 'file-name-area' : uploadedFile.file && uploadedFile.name }">
      <div class="uploaded-file-name file-name-mobile" v-if="uploadedFile.file">
        {{ uploadedFile.name }}
      </div>
      <div v-if="uploadedFile.file && uploadedFile.name"
        class="remove-wrap">
        <i class="material-icons remove-icon"
          @click="uploadedFile = { file:'', name: '' }">close</i>
      </div>
    </div>

    <!-- 템플릿양식 -->
    <a v-if="templateType && !showImage"
      href="launchpack.co.kr" target="_blank">
      <div class="template-highlight">{{ templateType }}</div>
    </a>

    <sweet-modal ref="previewModal" overlay-theme="dark">
      <img class="preview-img" :src="uploadedFile.file">
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue';

  export default {

    name: 'file-uploader',

    components: {
      SweetModal
    },

    props: {
      file: {
        type: Object,
        required: false
      },
      // id 부여
      type: {
        type: String,
        required: true
      },
      fileType: {
        type: String,
        required: true
      },
      placeholder: {
        type: String,
        required: true
      },
      showImage: {
        type: Boolean,
        required: false
      }
    },

    data () {
      return {
        uploadedFile: {
          file: '',
          name: ''
        },
        selectedImg: '',
        scrollTop: undefined
      }
    },

    created() {
      if (this.file) {
        this.uploadedFile = this.file;
      }
    },

    watch: {
      uploadedFile(n) {
        this.$emit('uploadedFile', n);
      }
    },

    computed: {
      inputId() {
        return this.type + '-' + this.fileType;
      },
      accept() {
        if(this.fileType === 'image') return 'image/*';
        else if(this.fileType === 'ppt') return '.ppt, .pptx';
        else return '*/*';
      },

      templateType() {
        if (this.type === 'main') return '메인페이지 양식.pptx';
        else if (this.type === 'product') return '상품목록 페이지 양식.pptx';
        else if (this.type === 'product_detail') return '상품상세 페이지 양식.pptx';
        else return '';
      }
    },

    methods: {
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        let file = files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(e) {
          this.uploadedFile = {
            file: e.target.result,
            name: file.name
          };
        }.bind(this);
      }
    }
  }
</script>

<style scoped>

  @media (max-width: 600px) {
    .file-name-mobile {
      transform: translateY(4px);
    }
  }

  .file-input-upload {
    outline: 0;
    position: absolute;
    width: .01em;
  }

  .file-uploader {
    height: 44px;
    border-bottom: 2px solid #303030;
    margin: 0 12px;
  }

  .file-uploader.image-mode{
    height: 248px;
    border: none;
  }

  .file-attachment-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 80px;
  }

  .file-attachment-text {
    height: 28px;
    font-size: 12px;
    color: #fff;
    background: #c7c7c7;
    line-height: 28px;
    text-align: center;
    font-weight: 700;
    border-radius: 8px;
    padding: 0 12px;
  }

  .uploaded-file-image {
    width: 120px;
    height: 214px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #eee;
  }

  .file-name-area {
    height: 40px;
  }

  .uploaded-file-name {
    font-size: 16px;
    font-weight: 700;
    color: #7ad4ef;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal !important;
    overflow: hidden;
    padding-right: 32px;
  }

  .remove-wrap {
    position: absolute;
    right: 0;
    top: 11px;
    width: 20px;
    height: 20px;
    background: #e6e6e6;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .remove-wrap-image {
    position: absolute;
    right: 4px;
    top: 6px;
    width: 20px;
    height: 20px;
    background: #ff6600;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .material-icons.remove-icon{
    font-size: 15px;
    color: #fff;
    font-weight: 800;
  }

  .template-highlight {
    text-decoration: underline;
    font-weight: 700;
    font-size: 14px;
    color: #7ad4ef;
  }

  .preview-img {
    max-height: 80vh;
    border: 1px solid #dddddd;
  }
</style>
